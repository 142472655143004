import React, { FC } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion, Variants } from 'framer-motion';

import Link from 'components/atoms/Link';

import { MainCardProps } from './models.d';

const MainCard: FC<MainCardProps> = ({ description, image, altText, buttonLabel, buttonLink }) => {
  const cardVariants: Variants = {
    hidden: {
      y: 20,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.2,
        duration: 0.5,
      },
    },
  };

  return (
    <motion.div
      className="main-card"
      variants={cardVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.5 }}
    >
      <div className="main-card__content">
        <p className="main-card__description">{description}</p>
        {buttonLink && buttonLabel ? (
          <Link to={buttonLink} label={buttonLabel} customClass="main-card__link" />
        ) : null}
      </div>
      <GatsbyImage image={image} alt={altText} className="main-card__image" />
    </motion.div>
  );
};

export default MainCard;
