import * as React from 'react';
import { graphql } from 'gatsby';
import { motion, Variants } from 'framer-motion';

import Container from 'components/organisms/Container';
import Banner from 'components/molecules/Banner';
import MainCard from 'components/molecules/MainCard';
import NewsContainer from 'components/molecules/NewsContainer';
import QuoteCard from 'components/atoms/QuoteCard';
import Seo from 'components/atoms/Seo';

const IndexPage = ({
  data: {
    contentfulBanner: { bannerSlide },
    contentfulHomePage: {
      welcomeText,
      mainHeading,
      subheading,
      mainDescription: { mainDescription },
      mainButtonLink,
      secondDescription: { secondDescription },
      secondaryButtonLink,
      thirdDescription: { thirdDescription },
      thirdButtonLink,
      readMoreButtonLabel,
      inspirationalQuote,
      firstImage: {
        image: { gatsbyImageData: firstImage },
        altText: firstAlt,
      },
      secondImage: {
        image: { gatsbyImageData: secondImage },
        altText: secondAlt,
      },
      thirdImage: {
        image: { gatsbyImageData: thirdImage },
        altText: thirdAlt,
      },
      quoteImage: {
        image: { gatsbyImageData: quoteImage },
        altText: quoteAlt,
      },
    },
    contentfulNewsContainer,
  },
}) => {
  const headingVariants: Variants = {
    hidden: {
      x: '-100vw',
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        delay: 1.2,
        duration: 0.4,
        type: 'spring',
        stiffness: 100,
      },
    },
  };

  const subheadingVariants: Variants = {
    hidden: {
      x: '100vw',
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        delay: 1.5,
        duration: 0.4,
        type: 'spring',
        stiffness: 100,
      },
    },
  };

  const welcomeVariants: Variants = {
    hidden: {
      y: -30,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 1.5,
        duration: 0.4,
      },
    },
  };

  const welcomeWrapperVariants: Variants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 1,
        duration: 0.4,
      },
    },
  };

  return (
    <div className="home-page">
      <Seo title="Home" />
      <Banner slides={bannerSlide} />
      <Container>
        <NewsContainer {...contentfulNewsContainer} />
        <motion.div
          className="home-page__welcome-wrapper"
          variants={welcomeWrapperVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.p
            className="home-page__welcome"
            variants={welcomeVariants}
            initial="hidden"
            animate="visible"
          >
            {welcomeText}
          </motion.p>
          <motion.h1
            className="home-page__heading"
            variants={headingVariants}
            initial="hidden"
            animate="visible"
          >
            {mainHeading}
          </motion.h1>
          <motion.h2
            className="home-page__subheading"
            variants={subheadingVariants}
            initial="hidden"
            animate="visible"
          >
            {subheading}
          </motion.h2>
        </motion.div>
        <MainCard
          image={firstImage}
          description={mainDescription}
          altText={firstAlt}
          buttonLabel={readMoreButtonLabel}
          buttonLink={mainButtonLink.slug}
        />
        <MainCard
          image={secondImage}
          description={secondDescription}
          altText={secondAlt}
          buttonLabel={readMoreButtonLabel}
          buttonLink={secondaryButtonLink.slug}
        />
        <MainCard
          image={thirdImage}
          description={thirdDescription}
          altText={thirdAlt}
          buttonLabel={readMoreButtonLabel}
          buttonLink={thirdButtonLink.slug}
        />
      </Container>
      <QuoteCard image={quoteImage} altText={quoteAlt} quote={inspirationalQuote} />
    </div>
  );
};

export const query = graphql`
  {
    contentfulHomePage {
      welcomeText
      mainHeading
      subheading
      mainDescription {
        mainDescription
      }
      secondDescription {
        secondDescription
      }
      thirdDescription {
        thirdDescription
      }
      inspirationalQuote {
        raw
      }
      backgroundImage {
        gatsbyImageData(formats: WEBP)
      }
      firstImage {
        altText
        image {
          gatsbyImageData(formats: WEBP)
        }
      }
      secondImage {
        altText
        image {
          gatsbyImageData(formats: WEBP)
        }
      }
      thirdImage {
        altText
        image {
          gatsbyImageData(formats: WEBP)
        }
      }
      quoteImage {
        altText
        image {
          gatsbyImageData(formats: WEBP)
        }
      }
      mainButtonLink {
        slug
      }
      secondaryButtonLink {
        slug
      }
      thirdButtonLink {
        slug
      }
      readMoreButtonLabel
    }
    contentfulBanner(entry: { eq: "Hero Banner" }) {
      bannerSlide {
        title
        subtitle
        image {
          image {
            gatsbyImageData(formats: WEBP)
          }
          altText
        }
        buttonReference {
          buttonLabel
          reference {
            ... on ContentfulActivities {
              slug
            }
            ... on ContentfulOurProgram {
              slug
            }
            ... on ContentfulPersonnel {
              slug
            }
          }
        }
      }
    }
    contentfulNewsContainer {
      newsLabel
      showMoreButtonText
      showLessButtonText
      news {
        newsName
        startDate(formatString: "DD.MM.YYYY")
        endDate(formatString: "DD.MM.YYYY")
        newsContent {
          newsContent
        }
      }
    }
  }
`;

export default IndexPage;
