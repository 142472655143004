import React, { FC } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { motion, Variants } from 'framer-motion';

import { QuoteCardProps } from './models.d';

const QuoteCard: FC<QuoteCardProps> = ({ image, altText, quote }) => {
  const quoteVariants: Variants = {
    hidden: {
      y: 20,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.2,
        duration: 0.5,
      },
    },
  };

  return (
    <motion.div
      className="quote-card"
      variants={quoteVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.5 }}
    >
      <GatsbyImage image={image} alt={altText} className="quote-card__image" />
      <div className="quote-card__quote">{renderRichText(quote)}</div>
    </motion.div>
  );
};

export default QuoteCard;
