import React, { FC, useState } from 'react';
import { motion, Variants } from 'framer-motion';

import Button from 'components/atoms/Button';
import NewsItem from 'components/atoms/NewsItem';

import { NewsContainerProps } from './models.d';

const NewsContainer: FC<NewsContainerProps> = ({
  newsLabel,
  news,
  showMoreButtonText,
  showLessButtonText,
}) => {
  const [newsLength, setNewsLength] = useState<number>(1);

  const newsVariants: Variants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.4,
        duration: 0.6,
      },
    },
  };

  const handleClick = () => {
    if (newsLength === 1) setNewsLength(news.length);
    else setNewsLength(1);
  };

  return news ? (
    <motion.section
      className="news-container"
      variants={newsVariants}
      initial="hidden"
      animate="visible"
    >
      <p className="news-container__label">{newsLabel}</p>
      <div className="news-container__wrapper">
        {news.slice(0, newsLength).map((event) => (
          <NewsItem key={event.newsName} {...event} />
        ))}
      </div>
      {news.length > 1 ? (
        <Button
          buttonLabel={newsLength === 1 ? showMoreButtonText : showLessButtonText}
          variant="primary-solid"
          customClass="news-container__button"
          onClick={() => handleClick()}
        />
      ) : null}
    </motion.section>
  ) : null;
};

export default NewsContainer;
