import React, { FC } from 'react';

import { NewsItemProps } from './models.d';

const NewsItem: FC<NewsItemProps> = ({ newsName, startDate, endDate, newsContent }) => (
  <div className="news-item">
    <div className="news-item__date-wrapper">
      <span className="news-item__date">{startDate}</span>
      <span className="news-item__delimiter" />
      <span className="news-item__date">{endDate}</span>
    </div>
    <p className="news-item__name">{newsName}</p>
    <div className="news-item__content">{newsContent.newsContent}</div>
  </div>
);

export default NewsItem;
